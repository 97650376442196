import { closeCurrentModal, openDialog } from '@kcd/app-interface/dist/web2app';
import { Button } from '@kcd/feather';

function isEOS(): boolean {
  const now = new Date();
  const eosDate = new Date('2025-01-07T00:00:00');
  return now >= eosDate;
}

function useEndOfServiceNotice() {
  React.useEffect(() => {
    if (!isEOS()) return;

    openDialog({
      type: 'alert',
      title: '[알림] 직원관리 서비스 종료 안내',
      description: `안녕하세요, 캐시노트입니다.

직원관리 서비스는 2025년 1월 7일자로 종료되었습니다.

급여명세서 발송 이력은 캐시노트 고객센터를 통해 2028년 2월말까지 확인 가능하십니다.

감사합니다.`,
    });
  }, []);
}

function RedirectToHome() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="text-center px-20 mb-32">
        안녕하세요, 캐시노트입니다.
        <br />
        직원관리 서비스는 2025년 1월 7일자로 종료되었습니다.
        <br />
        급여명세서 발송 이력은 캐시노트 고객센터를 통해 2028년 2월말까지 확인
        가능하십니다.
        <br />
        감사합니다.
      </div>
      <Button
        onClick={() => {
          closeCurrentModal({
            fallback: () => window.location.replace('https://apps.cashnote.kr'),
          });
        }}
      >
        홈으로
      </Button>
    </div>
  );
}

function EndOfServiceRouteGuard({ children }: { children: React.ReactNode }) {
  useEndOfServiceNotice();

  if (!isEOS()) return <>{children}</>;

  return <RedirectToHome />;
}

export default function withEOSRouteGuard<P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) {
  return (props: P) => (
    <EndOfServiceRouteGuard>
      <Component {...props} />
    </EndOfServiceRouteGuard>
  );
}
