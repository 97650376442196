// In this file, all Page components from '.pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// '.pages/HomePage/HomePage.js'         -> HomePage
// '.pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set } from '@redwoodjs/router';

import { ToastWrapper } from 'src/layouts/ToastWrapper';
import { BusinessIdLimitWrapper } from 'src/layouts/BusinessIdLimitWrapper';
import { RouteHistoryProvider } from 'src/hooks/useRouteHistory';
import { RouteSessionStorageProvider } from 'src/hooks/useRouteSessionStorage';
import withEOSRouteGuard from './EOSRouteGuard';

//
const Routes = () => {
  return (
    <RouteSessionStorageProvider>
      <RouteHistoryProvider>
        <Router>
          <Set private unauthenticated="logIn" wrap={[ToastWrapper, BusinessIdLimitWrapper]}>
            {/* 직원관리 홈 */}
            <Route path="/staff-management/{businessId:Int}" page={StaffManagementHomePage} name="staffManagementHome" />

            {/* 급구 쿠폰 */}
            <Route path="/staff-management/{businessId:Int}/gubgoo/coupon" page={GubgooCouponPage} name="gubgooCoupon" />

            {/* 근무표 */}
            <Route path="/staff-management/{businessId:Int}/staffs" page={StaffsPage} name="staffs" />

            <Route path="/staff-management/{businessId:Int}/schedule/date/{year:Int}/{month:Int}/select-employee" page={ScheduleSelectEmployeePage} name="scheduleSelectEmployee" />
            <Route path="/staff-management/{businessId:Int}/schedule/date/{year:Int}/{month:Int}/employee/{employeeId:Int}/select-working-dates" page={ScheduleEmployeeSelectWorkingDatesPage} name="scheduleEmployeeSelectWorkingDates" />
            <Route path="/staff-management/{businessId:Int}/schedule/dates/{year:Int}/{month:Int}/{monthDates:String}/employee/{employeeId:Int}/select-working-hours" page={ScheduleEmployeeSelectWorkingHoursPage} name="scheduleEmployeeSelectWorkingHours" />
            <Route path="/staff-management/{businessId:Int}/schedule/dates/{year:Int}/{month:Int}/expected-salary" page={ScheduleMonthExpectedSalaryPage} name="scheduleMonthExpectedSalary" />
            <Route path="/staff-management/{businessId:Int}/schedule/date/{year:Int}/{month:Int}/{dateNum:Int}/add-employee" page={AssignStaffToDatePage} name="scheduleDateNewEmployee" />

            {/* 직원 추가/열람&변경/리스트 */}
            <Route path="/staff-management/{businessId:Int}/employees/new" page={EmployeesNewEmployeePage} name="newEmployee" />
            <Route path="/staff-management/{businessId:Int}/employees/{employeeId:Int}/edit" page={EmployeesEditEmployeePage} name="editEmployee" />

            {/* 급여계산기 */}
            <Route path="/staff-management/{businessId:Int}/calculator" page={SalaryCalculatorPage} name="salaryCalculator" />

            {/* 적정 시급 찾기 */}
            <Route path="/staff-management/{businessId:Int}/prevailing-wage/{sector}/{role}" page={PrevailingWagePrevailingWagePage} name="prevailingWage" />
            <Route path="/staff-management/{businessId:Int}/prevailing-wage/sector" page={PrevailingWageSectorSelectionPage} name="prevailingWageSector" />

            {/* 채용/고용 안정 지원금 */}
            <Route path="/staff-management/{businessId:Int}/funds/faq/{slug}" page={FundsFundFaqPage} name="fundFaq" />
            <Route path="/staff-management/{businessId:Int}/funds" page={FundsFundListPage} name="fundList" />
            <Route path="/staff-management/{businessId:Int}/funds/{slug}" page={FundsFundDetailPage} name="fundDetail" />
          </Set>

          <Route path="/login" page={LogInPage} name="logIn" />
          <Route path="/logout" page={LogOutPage} name="logOut" />
          <Route notfound page={NotFoundPage} />
        </Router>
      </RouteHistoryProvider>
    </RouteSessionStorageProvider>
  );
};

export default withEOSRouteGuard(Routes);
